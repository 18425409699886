import {getWxRequest} from '../api/api';

// 朋友圈
const defaultUrl = window.location.href;
const defaultTitle = '易企化';
const defaultImgUrl = 'https://mpjoy.oss-cn-beijing.aliyuncs.com/20230925/14bc0b2673704835b574504345d9305b.png';
const defaultDesc = '业财一体化财务软件\n商务合作：15880293295';
export default function wxShare(title = defaultTitle, imgUrl = defaultImgUrl, desc = defaultDesc, shareUrl = defaultUrl) {
    if (!isWeixinBrowser()) {
        return;
    }
    // let shareUrl = window.location.origin + '/client/' + window.location.hash;
    // let shareUrl = window.location.href;
    
    loadShareSignature();
    wx.ready(function () {
        desc = desc.replace('\\n\\r',"\n\r");
        desc = desc.replace('\\n',"\n");
        desc = desc.replace('\\r',"\r");
        
        console.log('desc :' + desc);
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
        wx.onMenuShareTimeline({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: shareUrl, // 分享链接
            imgUrl: imgUrl, // 分享图标
        });
        // 朋友
        wx.onMenuShareAppMessage({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: shareUrl, // 分享链接
            imgUrl: imgUrl, // 分享图标
        });
    });
    wx.error(p => {
        console.log(p)
    });
}

function loadShareSignature() {
    if (sessionStorage.shareSignature && sessionStorage.shareSignature != 'undefined') {
        let shareSignature = JSON.parse(sessionStorage.shareSignature);
        setShareConfig(shareSignature);
        return;
    }
    getWxRequest("/wxAuth/getShareSignature").then(resp => {
        console.log(resp)
        if (resp.data && resp.data.code !== 200) {
            return
        }
        sessionStorage.shareSignature = JSON.stringify(resp.data.data);
        setShareConfig(resp.data.data);
    })
}

function setShareConfig(shareSignature) {
    wx.config({
        debug: false,
        appId: shareSignature.appId,
        timestamp: shareSignature.wxTimestamp,
        nonceStr: shareSignature.wxNoncestr,
        signature: shareSignature.wxSignature,
        jsApiList: [
            'checkJsApi',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'chooseWXPay',
            'scanQRCode',
            'hideOptionMenu']
    });
}

function isWeixinBrowser() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        console.info('非微信浏览器');
        return false;
    }
}
